import React, {useState} from "react";
import { Nav, Navbar, NavItem, NavLink, NavbarToggler, NavbarBrand, Collapse} from "reactstrap";
import { BrowserRouter as Router, Route, Routes, Link} from "react-router-dom"

//Import styling
import './Navmenu.css'
//Import other pages:
import {Homepage} from "../Pages/Homepage/Homepage";
import {Social} from "../Pages/Social/Social";
import {Portfolio} from "../Pages/Portfolio/Portfolio";

export const Navmenu = () => {
    const [toggle, setToggle] = useState(false);

    const toggleBar = () => setToggle(!toggle);

    return (
        <>
            <Router>
            <Navbar color="light" light expand="md">
                <NavbarToggler onClick={() => toggleBar()}/>
                <NavbarBrand style={{fontSize: "25px", alignItems: "center"}}>Matthew Issa</NavbarBrand>
                <Collapse isOpen={toggle} navbar>
                <Nav className="ml-auto">
                    <NavItem >
                        <Link className="link size-2" to="/">Home</Link>
                    </NavItem>
                    <NavItem>
                        <Link className="link size-2" to="/Social">Socials</Link>
                    </NavItem>
                    <NavItem>
                        <Link className="link size-2" to="/Portfolio">Portfolio</Link>
                    </NavItem>
                </Nav>
                </Collapse>
            </Navbar>
            {/*  Routes  */}
            <Routes>
                <Route path="/" element={<Homepage/>}/>
                <Route path="/Social" element={<Social/>}/>
                <Route path="/Portfolio" element={<Portfolio/>}/>
            </Routes>
            </Router>
        </>
    );
}