import React from 'react';
import './App.css';
import { Navmenu } from "./Navmenu/Navmenu";

const App = () => {
  return (
      <>
        <div className="App">
            <Navmenu/>
        </div>
      </>
  );
}

export default App;
