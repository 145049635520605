import { useState} from "react";
import {Row, Col} from "reactstrap";
import {Button} from "reactstrap";

//imported icons from flaticon: Credit to 'Roundicons'
import right_arrow from "../../Assets/right-arrow.png"
import left_arrow from "../../Assets/left-arrow.png"

//3DCampusAR screenshots
import campusar1 from "../../Assets/3DCampusAR/3DAR2.png"
import campusar2 from "../../Assets/3DCampusAR/3DAR1.png"
import campusar3 from "../../Assets/3DCampusAR/3DAR4.png"
import campusar4 from "../../Assets/3DCampusAR/3DAR3.png"

//Tutlogger screenshots
import tutlogger1 from "../../Assets/Tutlogger/tutlogger-main-screen.png"
import tutlogger2 from "../../Assets/Tutlogger/tutlogger-add-screen.png"
import tutlogger3 from "../../Assets/Tutlogger/tutlogger-select-screen.png"
import tutlogger4 from "../../Assets/Tutlogger/tutlogger-time-screen.png"
import {ContentBox} from "../../Assets/ContentBox/ContentBox";



let para1 = "UCLA Coursework:" + "\n" +
" Fall 2022 - Algorithms and Complexty (CS180), Intro to Computer Graphics (CS174a)," +
    " Intro to Linguistic Analysis (Ling20)" + "\n" +
" Winter 2022 - Software Construction (CS35L), Syntax I (Ling120B), General Phonetics (Ling103)" + "\n" +
" Spring 2023 - Intro to AI (CS161), Intro to Operating Systems (CS111), Semantics I (Ling 120C) " +
    " Phonology I (Ling120A) "

const First = () => (
        <>
            <div className="body">
                <div className="box">
                    <h1>3D Campus Ar</h1>
                    <p>An augmented reality (AR) navigation app for Foothill High School that
                        allows students to digitally navigate the school campus  </p>
                    <p><strong>Highlighted Features:</strong></p>
                    <p>- Using Graphs and Vertices to plot building allowed us to calculate quickest path to
                    your destination from your current location</p>
                    <p>- Built using AR Technology for IOS using Unity Game Engine</p>
                    <div style={{alignItems: "center"}}>
                        <Row>
                            <Col><img width={300} src={campusar1}/></Col>
                            <Col><img width={300} src={campusar2}/></Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col><img width={300} src={campusar3}/></Col>
                            <Col><img width={300} src={campusar4}/></Col>
                        </Row>
                        <div style={{paddingBottom: "10pt"}}/>
                    </div>
                </div>
            </div>
        </>
    )

const Second = () => (
    <>
        <div className="body">
            <div className="box">
                <h1><a href="https://github.com/IssaAboudi/tutlogger" target="_blank" style={{ textDecoration: "none", color: "black" }}>Tutlogger</a></h1>
                <p>A GUI based hour logging application for tutors and educators working
                one on one with students. Features usage of OpenGL rendering using ImGUI</p>
                <p><strong>Highlighted Features:</strong></p>
                <p>- Save tutees you are working with in your application</p>
                <p>- Stopwatch Timer time tracking feature that automatically updates your log</p>
                <div style={{alignItems: "center"}}>
                    <Row>
                        <Col><img width={550} src={tutlogger1}/></Col>
                        <Col><img width={550} src={tutlogger2}/></Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col><img width={550} src={tutlogger3}/></Col>
                        <Col><img width={550} src={tutlogger4}/></Col>
                    </Row>
                </div>
            </div>
        </div>
    </>
)

const Third = () => (
    <>
        <div className="box" style={{height: "970px"}}>
            <h1>Bruin Brick Breaker</h1>
            <p>A recreation of the classic Atari game known as Breakout!</p>
            <iframe src="https://issaaboudi.github.io/174a-FPJ/"  width="1200px" height="1000px"
            style={{transform: "scale(0.8)", transformOrigin: "0 0"}}></iframe>
        </div>
    </>
)

const Fourth = () => (
    <>
        <div className="box">
            <h1><a href="https://github.com/IssaAboudi/rustwrd">RUSTWRD</a></h1>
            <p>A simple text editor built from scratch only using Rust</p>
            <p><strong>Highlighted Features:</strong></p>
            <p>- Open files</p>
            <p>- Edit files</p>
            <p>- Save files</p>
        </div>
    </>
)

const components = [
    <First/>,
    <Second/>,
    <Third/>,
]

export const Portfolio = () => {

    const [index, setIndex] = useState(0);

    const previous = () => {
        setIndex(oldIndex => (oldIndex - 1 + components.length) % components.length);
    }

    const next = () => {
        setIndex(oldIndex => (oldIndex + 1) % components.length);
    }

    let Current = components[index];

    return (
        <>
            <div className="body">
                <ContentBox title="My Projects!" body="Click on the arrows to flip through the different projects I have worked on!"/>
                <div>
                    <button style={{border: "none", outline: "none", backgroundColor: "white"}} onClick={previous}><img src={left_arrow} style={{width: "30pt", paddingRight: "5px"}}/></button>
                    <button style={{border: "none", outline: "none", backgroundColor: "white"}} onClick={next}><img src={right_arrow} style={{width: "30pt", paddingLeft: "5px"}}/></button>
                </div>
                {Current}
            </div>
        </>
    );
}