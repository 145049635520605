

import github from "../../Assets/icons/github.png";
import medium from "../../Assets/icons/medium.png";
import linkedin from "../../Assets/icons/linkedin.png";
import youtube from "../../Assets/icons/youtube.png";
import {Col, Row} from "reactstrap";


export const Social = () => {

    return (
        <>
            <div className="body">
                <div className="box" style={{boxShadow: "none"}}>
                    <p className="size-3"> If you need to get in contact with me, the best way to reach me is by email at <a href="mailto:me@matthewissa.com">me@matthewissa.com</a></p>
                    <br/>
                    <p>I share a lot of what I work on on social media, check them out below! </p>
                    <div className="socials" style={{display: "grid", gridTemplateColumns: "repeat(2, .5fr)"}}>
                        <div className="box">
                            <a href="https://github.com/IssaAboudi" target="_blank"><img src={github} width="100px"/></a>
                        </div>
                        <div className="box">
                            <a href="https://medium.com/@issaaboudi" target="_blank"><img src={medium} width="100px"/></a>
                        </div>
                        <div className="box">
                            <a href="https://www.linkedin.com/in/matthew-issa-aboudi-54053a208/" target="_blank"><img src={linkedin} width="100px"/></a>
                        </div>
                        <div className="box">
                            <a href="https://www.youtube.com/@matthewissa" target="_blank"><img src={youtube} width="120px"/></a>
                        </div>
                        {/*empty div to make grid look good*/}
                        <div/>
                    </div>
                </div>
            </div>
        </>
    );
}