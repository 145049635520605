import profile from "../../Assets/Issa-Circle-Picture.png"



let para1 = ""

export const Homepage = () => {

    return(
        <>
            <div className="body">
                <div className="title-name container">
                    <img src={profile} width="50%"/>
                    <div className="column">
                        <h1>My name is Issa and I'm a Developer</h1>
                    </div>
                </div>
                <div className="box">
                    <p>My name is Matthew Issa Aboudi but I go by Issa (isʌ). I am a Bay Area Native currently studying Linguistics and Computer Science
                    at UCLA. In addition to coding, I enjoy learning languages, traveling and writing!</p>
                    <br/>
                    <p>Most of my classmates know who I am because I participate often in class and share my notes.
                        I develop for all platforms and have lots of experience with front end frameworks like React and Dioxus.
                        I also have experience developing systems level applications and OpenGL/WebGL rendering! </p>
                </div>
            </div>
        </>
    )
}