import './ContentBox.css'
import './font.css'

interface ContentBoxProps {
    title?: string,
    body?: string,
}

export const ContentBox: React.FC<ContentBoxProps> = ({title, body}) =>{

    const lines = body ? body.split('\n') : [] ;

    return(
        <>
            <div className="box">
                {title && <h1>{title}</h1> }
                {lines.map((line, index) => {
                    return <p key={index}>{line}</p>;
                })}
            </div>
        </>
    );
}